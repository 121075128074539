import axios from 'axios'

export default {
  async updateUser (context, userPayload) {
    console.log('Action: updateUser')
    const { username, userToken } = userPayload
    let userData

    try {
      await axios.get(`http://${process.env.VUE_APP_LOCAL_IP_ADDRESS}/auth/get-user-details-by-username/${username}`)
        .then((result) => {
          console.log(result)
          userData = result
        })
    } catch (err) {
      console.error(err)
    }

    const payload = { userData, userToken }

    context.commit('setUser', payload)
  },
  logoutUser(context) {
    console.log('Action: logoutUser')

    context.commit('unsetUser')
  }
}