export default {
  isLoggedIn(state) {
    if (state.user.userToken) {
      return true
    } else {
      return false
    }
  },
  isLoggedInAsAdmin(state) {
    if (state.user.userToken && state.user.userData.data.status === 'ADMIN') {
      return true
    } else {
      return false
    }
  },
  getUserGender(state) {
    return state.user.userData.data.gender
  },
  getUserStatus(state) {
    return state.user.userData.data.status
  },
  getUserFullname(state) {
    return state.user.userData.data.fullName
  },
  getUserIDNumber(state) {
    return state.user.userData.data.identificationNumber
  },
  getUserToken(state) {
    return state.user.userToken
  }
}