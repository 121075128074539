import { Store } from 'vuex'
import Vuetify from '../../plugins/vuetify'

export default {
  /**
   * Theme and Layout
   */
  setGlobalTheme: (state, theme) => {
    Vuetify.framework.theme.dark = theme === 'dark'
    state.globalTheme = theme
  },
  setRTL: (state, isRTL) => {
    Vuetify.framework.rtl = isRTL
    state.isRTL = isRTL
  },
  setUser(state, payload) {
    console.log(payload)
    state.user.userData = payload.userData
    state.user.userToken = payload.userToken
  },
  unsetUser(state) {
    console.log('unsetUser')
    state.user.userData = null
    state.user.userToken = null
  }  
}
