import Vue from 'vue'
import store from '../store'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [{
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'home',
    component: () => import(/* webpackChunkName: "landing-page" */ '@/pages/LandingPage.vue')
  }]
}, 
{
  path: '/contact',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'contact',
    component: () => import(/* webpackChunkName: "login" */ '@/pages/Contact.vue')
  }]
}, 
// {
//   path: '/login',
//   component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
//   children: [{
//     path: '',
//     name: 'login',
//     component: () => import(/* webpackChunkName: "login" */ '@/pages/Login.vue')
//   }]
// }, 
// {
//   path: '/register',
//   component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
//   children: [{
//     path: '',
//     name: 'register',
//     component: () => import(/* webpackChunkName: "register" */ '@/pages/Register.vue')
//   }]
// }, 
// {
//   path: '/kiosk',
//   component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
//   children: [{
//     path: '',
//     name: 'kiosk',
//     component: () => import(/* webpackChunkName: "register" */ '@/pages/Kiosk.vue')
//   }]
// }, 
// {
//   path: '/portal',
//   meta: {
//     requiresAuth: true
//   },
//   component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/UserPortalLayout.vue'),
//   children: [{
//     path: 'dashboard',
//     name: 'dashboard',
//     component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/UserDashboard.vue')
//   },{
//     path: 'door-access',
//     name: 'door-access',
//     component: () => import(/* webpackChunkName: "door-access" */ '@/pages/DoorAccess.vue')
//   }, {
//     path: 'digital-locker',
//     name: 'digital-locker',
//     component: () => import(/* webpackChunkName: "digital-locker" */ '@/pages/DigitalLocker.vue')
//   }]
// }, 
// {
//   path: '/admin',
//   meta: {
//     requiresAdminAuth: true
//   },
//   component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/AdminPortalLayout.vue'),
//   children: [{
//     path: 'dashboard',
//     name: 'dashboard',
//     component: () => import(/* webpackChunkName: "dashboard" */ '@/pages/adminportal/AdminDashboard.vue')
//   },{
//     path: 'verify-member',
//     name: 'verify-member',
//     component: () => import(/* webpackChunkName: "verify-member" */ '@/pages/adminportal/VerifyMember.vue')
//   },
//   {
//     path: 'digital-locker',
//     name: 'admin-digital-locker',
//     component: () => import(/* webpackChunkName: "admin-digital-locker" */ '@/pages/adminportal/AdminDigitalLocker.vue')
//   }
//   ]
// }, 
{
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
  }]
}]

const router = new Router({
  mode: 'history',
  hashbang: false,
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    if (to.hash) {
      return { selector: to.hash }
    }

    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  console.log(store.getters['app/isLoggedIn'])
  if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
    if (!store.getters['app/isLoggedInAsAdmin']) {

      return next({ name: 'error' })
    } else {
      return next()
    }
  }
  else if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if Logged In
    // if not, redirect to login page
    console.log('matchedloop')
    if (!store.getters['app/isLoggedIn']) {
      console.log('notauthenticated')

      return next({ name: 'home' })
    } else {
      console.log('authenticated')

      return next()
    }
  } else {
    console.log('normal')

    return next()
  }
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
